import React, { useEffect, useState }  from "react";
import { confirmAlert } from 'react-confirm-alert'; 
import { http, apiBaseUrl, notify } from '../../utitility';
import { Link } from "react-router-dom";
import LinkContactButton from '../LinkContactButton';
import CustomModal from "../Modal/CustomModal";
import ChangeDecision from '../ChangeDecision'
import RecommendListing from "../RecommendListing"
import EditTag from '../EditTag';

const ActionField = ({user, listing, reloadTableData,sphinx}) => {

	const [selectedOption,setselectedOption] = useState({});
	const [IsOpenDecisionModal,openDecisionModal] = useState(false);
  const [listing_id,setListingID] = useState('');
  useEffect(() => {
    setListingID(listing.ListingID)
    // if(sphinx){
	// 		setListingID(listing.listingid)
	// 	}else{
	// 		setListingID(listing.ListingID)
	// 	}
  },[listing]);

	const toggleDecisionModal = () => {
		openDecisionModal(!IsOpenDecisionModal);
	}

	const ConfirmAlert = (value) => {
		confirmAlert({
			message: 'Do you really want to delete this note?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => deleteListing(value)
				},
				{
					label: 'No',
					onClick: () => {}
				}
			]
		});
	}

	const deleteListing = (listing_id) => {
		http.delete(apiBaseUrl(`/api/deleteListing?listing_id=${listing_id}`))
		.then(() => {
			reloadTableData();
			notify.success("Deleted successfully");
		}).catch((error)=>{
			let errorMsg = (error.response && error.response.data && error.response.data.message) ? 
											error.response.data.message :
											'whoops...something went wrong';
			notify.error(errorMsg);
		});
	}

	const changeDecision = (value) =>{
		setselectedOption(value);
    openDecisionModal(value);
	}

  return (
				<React.Fragment>
					{(user.UserType == 40 || user.UserType == 30 )&&
					   <React.Fragment>
								<Link to={`/listings?listing_id=${listing_id}`} className="mr-2 table_font_size btn-link">Edit</Link>
								{user.UserType === 40 && 
									<span onClick={()=>ConfirmAlert(listing_id)} className="mr-2 table_font_size btn-link link-pointer">Delete</span>
								}
								{listing.AvailableStatus == 0 && 
									<Link to={`/publish_listing?listing_id=${listing_id}`} className="table_font_size btn-link mr-2">Submit</Link>
								}
								<LinkContactButton sphinx={sphinx} listing={listing}/>
								<RecommendListing listing={listing} listing_id={listing_id} reloadTableData ={reloadTableData}/>
								<span className="ml-2"><EditTag className="mr-2 table_font_size btn-link" listing={listing}/></span>
						 </React.Fragment>
							
					}
					{user.UserType == 15 &&
	          <div onClick={()=>changeDecision(listing)} className=" btn-link table_font_size">Decision</div>
					}
					{user.UserType == 1 &&
	          <React.Fragment>
							<Link to={`/listings?listing_id=${listing_id}`} className="mr-2 table_font_size btn-link">Edit</Link>
							<span onClick={()=>ConfirmAlert(listing_id)} className="mr-2 table_font_size btn-link link-pointer">Delete</span>
							{listing.AvailableStatus == 0 && 
								<Link to={`/publish_listing?listing_id=${listing_id}`} className="table_font_size btn-link mr-2">Submit</Link>
							}
						</React.Fragment>
					}
					{IsOpenDecisionModal &&
							<CustomModal notice={true} modal={IsOpenDecisionModal}  toggle={toggleDecisionModal} closeAllModal={toggleDecisionModal} heading={"Change Decision Status"}  render={() => (  
									<ChangeDecision listing={true}  listings={listing} closeAllModal={toggleDecisionModal} reloadTableData={() => reloadTableData()} CurrentDetail={selectedOption} user={user} suser_id={user.UserID}/>
							)}/>
						}
				</React.Fragment>
  );
};
export default ActionField;