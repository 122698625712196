import React, { useState, useRef, useEffect }  from "react";
import CustomModal from "./Modal/CustomModal";
import AsyncSelect from "react-select/async";
import { hasKeys, apiBaseUrl,http, notify } from "../utitility";

import classnames from "classnames";

const LinkContactButton = ({listing,classNameValue,getListingContact, sphinx}) => {

	const [isModalOpen,setIsModalOpen] = useState(false);
	const [listing_id,setListingId] = useState("");
	const [isSubmitting,setIsSubmitting] = useState(false);
	const [selectedValue,setSelectedValue] = useState(0);
	const [currentPage,setCurrentPage] = useState(1);
	const [totalPages,setTotalPages] = useState(0);
	const elRef = useRef(null);
  
	useEffect(() => {
		setListingId(listing.ListingID)
		// if(sphinx){
		// 		setListingId(listing.ListingID)
		// 	}else{
		// 		setListingId(listing.ListingID)	
		// 	}
  },[listing]);

	const openLinkContact = () => {
		setIsModalOpen(true);
	}

	const CustomMenu = ({ innerRef, innerProps, isDisabled, children }) => (
		<div ref={innerRef} {...innerProps} className="customReactSelectMenu">
				{children}
				<button
						className="btn btn-info btn-sm btn-block"
						onClick={() => test()}
				>Load More</button>
		</div>
		)

	const SaveContactLink = () => { 
		if(selectedValue !== 0) {
			let data ={};
			setIsSubmitting(true);
			data.ListingId = listing_id;
			data.ContactId = selectedValue.ContactId;
			http.post(apiBaseUrl(`/api/linkContact`),data)
			.then((res) => {
				if(res.data.message){
				notify.success(res.data.message);
				}else{
				notify.success("Linked successfully");
				}
				toggle();
				getListingContact&&getListingContact(listing_id);
				setIsSubmitting(false);
			}).catch((error)=>{
				notify.error(error);
				setIsSubmitting(false);
			});
		} else {
			notify.error('Please select any contact');
			setIsSubmitting(false);
		} 
	}

	const toggle = () => {
		setSelectedValue(0);
		setIsModalOpen(!isModalOpen);
	}

	const handleItemSelectChange=(option)=>{
		setSelectedValue(option);
 	}

	const getOptionLabel = (firstName,LastName) => {
		let name = firstName ? firstName : "";
		name += LastName ? " " +LastName : "";
		return name;
	}

	const loadOptions = (inputValue, callback) => {
		let page = currentPage;
		if(inputValue === 'fromLoadMore'){
			page+=1;
		}
		let url = `/api/getLinkContacts?page=${page}`;
		if(inputValue &&inputValue != 'fromLoadMore'){
			url = `/api/getLinkContacts?SearchString=${inputValue}&page=${page}`
		}
		http.get(apiBaseUrl(url))
			.then((res) => {
				setTotalPages(res.data.total_pages)
				setCurrentPage(res.data.current_page)
				callback(res.data.data)
			}).catch((error=>{
				console.log(error,'error')
			}))
	}

  return (
		<React.Fragment>
				{hasKeys(listing)&&
				<span key={listing_id}>
						<span onClick={()=>(openLinkContact())} className={classNameValue? classNameValue :"btn-link link-pointer mr-2 table_font_size"}>Link Contact</span>
						{isModalOpen &&
							<CustomModal notice={true} modal={isModalOpen}  toggle={toggle} closeAllModal={toggle} heading={"Link to Contact"}  render={() => (  
								<React.Fragment>
									<h5 className="text-secondary mb-3">Who did you create this listing for?</h5>
									<b className="text-secondary">Select the contact:</b>
									<AsyncSelect
										cacheOptions
										defaultOptions
										value={selectedValue}
										getOptionLabel={e => getOptionLabel(e.Firstname,e.Lastname)}
										getOptionValue={e => e.ContactId}
										loadOptions={loadOptions}
										ref={elRef}
										isClearable={true}
										onChange={handleItemSelectChange}
										placeholder="Search contact to be linked"
										components={{ Menu: CustomMenu }}
									/>
									<div className="text-center">
										<button 
											className={classnames({
												"buttonload mt-2 btn btn-primary btn-sm": isSubmitting === true,
												"btn mt-2 btn-primary btn-sm": isSubmitting === false,
											})}
											disabled={isSubmitting} 
											onClick={(value)=>SaveContactLink(value)}
										>
											Save
										</button>
										<button 
											className="ml-3 btn mt-2 btn-secondary btn-sm"
											onClick={()=>toggle()}
										>
											Close
										</button>
									</div>
								</React.Fragment>
							)}/>
      			}
				</span>}
			</React.Fragment>
  );
};
export default LinkContactButton;
