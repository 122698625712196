import React, { useEffect } from "react";
import { Formik } from "formik";
import CustomButton from "./CustomButton";
import { Row, Col } from "reactstrap";
import { hasKeys, http, apiBaseUrl } from "../../utitility";
import { listingStatus } from "../../utitility/constants";
import { filter, isEmpty } from "lodash";

const SearchListingForm = ({
  setUrl,
  reloadTableData,
  user,
  setbuyerListingStatusMessage,
}) => {
  const cleanObj = (obj) => {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ""
      ) {
        delete obj[propName];
      }
    }
    return obj;
  };

  useEffect(() => {
    let searchListingQuery = localStorage.getItem("searchListingQuery");
    setUrl(searchListingQuery);
    reloadTableData();
  });

  const clean = (array) => {
    return array.filter(function (el) {
      return el != null;
    });
  };

  const buildQuery = (params) => {
    var esc = encodeURIComponent;
    var query = Object.keys(params).map(function (k) {
      if (esc(params[k])) {
        return esc(k) + "=" + esc(params[k].trim());
      }
    });
    if (query) {
      query = clean(query).join("&");
    }
    return query;
  };

  const getListingStatusLabel = (status) => {
    if (!isEmpty(listingStatus)) {
      let data = filter(listingStatus, function (o) {
        if (parseInt(o.value) === parseInt(status)) return o;
      });
      if (data.length) {
        return data[0].label;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  return (
    <Formik
      initialValues={{
        listing_number: "",
        patent_number: "",
        with_all: "",
        with_exact: "",
        with_out: "",
        with_atleast_one: "",
        patent_class: "",
        infringement: ""
      }}
      validate={(values) => {
        const errors = {};
        if (!hasKeys(cleanObj(values))) {
          errors.listing_number = "please enter at least one value to search";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        if (user.UserType === 15 && values.listing_number) {
          let listingNumber = values.listing_number;

          http
            .get(apiBaseUrl(`/api/getListingById?listing_id=${listingNumber}`))
            .then((res) => {
              if (
                res &&
                res.data &&
                res.data.data &&
                res.data.data.AvailableStatus === 1
              ) {
                let query = buildQuery(values);
                query = `searchListing?${query}&userType=${user.UserType}&userID=${user.UserID}&`;
                setUrl(query);
                window.localStorage.setItem("searchListingQuery", query);
                setbuyerListingStatusMessage("");
                reloadTableData();
              } else if (res && res.data && res.data.data !== null) {
                let statuslabel = getListingStatusLabel(
                  res.data.data.AvailableStatus
                );
                const buyerListingStatusMessage = `The listing ${res.data.data.ListingID} is marked as ${statuslabel}`;
                setbuyerListingStatusMessage(buyerListingStatusMessage);

                let query = buildQuery(values);
                query = `searchListing?${query}&userType=${user.UserType}&userID=${user.UserID}&`;
                setUrl(query);
                reloadTableData();
              } else {
                setbuyerListingStatusMessage("");
                let query = buildQuery(values);
                query = `searchListing?${query}&userType=${user.UserType}&userID=${user.UserID}&`;
                setUrl(query);
                reloadTableData();
              }
            })
            .catch((error) => {
              console.log(error, "error");
            });
        } else {
          let query = buildQuery(values);
          query = `searchListing?${query}&userType=${user.UserType}&userID=${user.UserID}&`;
          setUrl(query);
          window.localStorage.setItem("searchListingQuery", query);
          setbuyerListingStatusMessage("");
          reloadTableData();
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <Row className="ml-2">
            <Col className="form-group col-md-2 mt-3">
              <input
                type="text"
                name="listing_number"
                className="form-control"
                onChange={handleChange}
                value={values.listing_number}
                placeholder="Listing number"
              />
              <span className="text-danger">
                {errors.listing_number &&
                  touched.listing_number &&
                  errors.listing_number}
              </span>
            </Col>
            <Col className="form-group md-2 mt-3">
              <input
                type="text"
                name="patent_number"
                className="form-control"
                onChange={handleChange}
                value={values.patent_number}
                placeholder="Patent number"
              />
              <span className="text-danger">
                {errors.patent_number &&
                  touched.patent_number &&
                  errors.patent_number}
              </span>
            </Col>
            <Col className="form-group md-2 mt-3">
              <input
                type="text"
                name="with_all"
                className="form-control"
                onChange={handleChange}
                value={values.with_all}
                placeholder="all these words"
              />
            </Col>
            {/* <Col className="form-group col-md-2 mt-3">
							<input
								type="text"
								name="with_out"
								className="form-control"
								onChange={handleChange}
								value={values.with_out}
								placeholder="with out these words"
							/>
						</Col> */}
            <Col className="form-group col-md-2 mt-3">
              <input
                type="text"
                name="with_atleast_one"
                className="form-control"
                onChange={handleChange}
                value={values.with_atleast_one}
                placeholder="at least one of these words"
              />
            </Col>
            <Col className="form-group md-2 mt-3">
              <input
                type="text"
                name="with_exact"
                className="form-control"
                onChange={handleChange}
                value={values.with_exact}
                placeholder="by phase"
              />
            </Col>
            <Col className="form-group md-2 mt-3">
              <input
                type="text"
                name="patent_class"
                className="form-control"
                onChange={handleChange}
                value={values.patent_class}
                placeholder="patent class"
              />
            </Col>
            <Col className="form-group md-2 mt-3">
              <select
                name="infringement"
                value={values.infringement}
                onChange={handleChange}
                aria-label="Default select example"
                className="form-control"
              >
                <option value="">Select infringement</option>
                <option value={1}>No</option>
                <option value={2}>Yes</option>
                <option value={0}>UnKnown</option>
              </select>
            </Col>
            <Col className="form-group md-2 mt-3">
              <CustomButton isSubmitting={isSubmitting} btnText="Search" />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default SearchListingForm;
