import React from "react";
import {  MyListing, TechAvailableListing, SearchListing, BrowsePatent, SearchPatent, CreatePatent } from "../Components";
import { useLocation } from 'react-router-dom';

const MyAccountPage = ({user}) => {
  const  location = useLocation();
  const pathName = location.pathname;

  return (
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              {pathName==="/myaccount/my_listing"&&<MyListing user={user}/>}
              {pathName==="/myaccount/myaccount_ta_all"&&<TechAvailableListing user={user}/>} 
              {pathName==='/myaccount/search' && <SearchListing user={user} />}
              {pathName==='/myaccount/patent' && <BrowsePatent user={user} />}
              {pathName=== '/patent/search' && <SearchPatent user={user}/>}
              {pathName=== '/patent/create' && <CreatePatent user={user}/>}   
              {/* {pathName==="/myaccount/bookmarked_listing"&&<BookmarkedListing user={user}/>} */}
              {/* {pathName=== '/myaccount/browse_listing' && <BrowseListing user={user}/>}
              {pathName=== '/patent/browse' && <BrowsePatent user={user}/>}          
              {pathName=== '/patent/search' && <SearchPatent user={user}/>}   
              {pathName=== '/myaccount/team_listing' && <TeamListing user={user}/>}            */}
            </div>
          </div>
  );
}
export default MyAccountPage;
