import React, {useRef,useEffect} from "react";
import { notify, http, apiBaseUrl, hasKeys, getStandardDateForm} from "../../utitility";
import { Formik } from "formik";
import CustomButton from './CustomButton';
import { useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const PatentNotesForm = ({user, reloadTableData, patentDetails,editValue}) => {
  const [messageData,setMessageData] = useState('');

  const [initialState,setInitialState] = useState({
    Tag:"",
    Message:"",
    UserID:user.UserID,
    PatentID:patentDetails ? patentDetails.PatentID : "",
    Share_type:0
  });

  useEffect(() => {
    if(hasKeys(editValue)){
      setMessageData(editValue.ConvertedMessage)
      setInitialState(editValue)
    }
  },[editValue]);
  
  const clearFormFields=(resetForm,setFieldValue)=>{
    resetForm({});
    setFieldValue('Message','')
    setMessageData('')
    setInitialState({
     Tag:"",
      Message:"",
      UserID:user.UserID,
      PatentID:patentDetails ? patentDetails.PatentID : "",
      Share_type:0
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialState}
      // validate={(values) => {
      //   const errors = {};
      //   if (!(values.file.size)) {
      //     errors.file = "Please select a file to upload";
      //   }
      //   return errors;
      // }}
      onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
        if(!values.NotesId){
          setSubmitting(true);
          let data = {...values};
          data.Message = messageData;
          data.CreateDate = getStandardDateForm();
          data.PatentID = patentDetails.PatentID;
          http.post(apiBaseUrl(`/api/patentNotes`),data)
          .then(() => {
            setSubmitting(false);
            reloadTableData();
            notify.success("Notes added successfully");
            clearFormFields(resetForm,setFieldValue);
          }).catch((error)=>{
            setSubmitting(false);
            clearFormFields(resetForm,setFieldValue);
            notify.error(error);
          });
        }else{
          setSubmitting(true);
          let data = {...values};
          data.Message = messageData;
          data.UpdateDate = getStandardDateForm();
          http.post(apiBaseUrl(`/api/patentNotes?notes_id=${values.NotesId}`),data)
          .then(() => {
            setSubmitting(false);
            notify.success("Notes updated successfully");
            resetForm({});
            reloadTableData();
            clearFormFields(resetForm,setFieldValue);
          }).catch((error)=>{
            setSubmitting(false);
            resetForm({});
            notify.error(error);
          });
        }
			
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
				isSubmitting,
        setFieldValue,
        resetForm
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Notes
            </label>
            <CKEditor
								editor={ ClassicEditor }
								data={messageData}
								onReady={ editor => {
									const data = editor.getData();
									console.log(data,'data value')
									setFieldValue('Message',data)
									setMessageData(data);
								} }
								onChange={ ( event, editor ) => {
										const data = editor.getData();
                    setFieldValue('Message',data)
                    setMessageData(data);
								} }
						/>
            <span className="text-danger">
              {errors.notes &&
                touched.notes &&
                errors.notes}
            </span>
          </div>
          <div className="form-group">
            <label
              className="font-weight-bold form-text"
              htmlFor="exampleInputEmail1"
            >
              Tag
            </label>
            <input
              type="text"
              name="Tag"
              className="form-control"
              onChange={handleChange}
              value={values.Tag}
            />
            <span className="text-danger">
              {errors.Tag &&
                touched.Tag &&
                errors.Tag}
            </span>
          </div>
          <div className="text-center py-2">
            <CustomButton isSubmitting={isSubmitting} btnText='Save' /> 
            <button type="button" onClick={(e) => clearFormFields(resetForm,setFieldValue)} className="ml-2 btn btn-secondary">Cancel</button>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default PatentNotesForm;
